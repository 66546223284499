import { createContext, useContext } from "react";
import { action, makeAutoObservable, makeObservable, observable, runInAction } from "mobx";
import { postMethodData, getMethodData, putMethod, delMethod, patchMethodData } from "../../ApiMethods/api";
import { toast } from "react-toastify";
const SEO_URL = process.env.REACT_APP_SEO_URL;





export class ProductCatalogStore {

    Token = null;
    rankList = [];
    Rank = "";
    gst = "";
    hsn = "";
    ProductCatalog = [];
    options_dropdown = [];
    shipping = "";
    option = "1";
    Videotitle = "";
    video_desc = "";
    keywords = "";
    editloadProductCatalog = false;
    ProductFormLoader = false;
    image_array = [];
    frame_image = [];
    combined_option_data = [];
    image_name_array = [];
    frame_name_array = [];

    options_in_combination = "";
    master_option = "null"
    design_id = "";
    addloadaddDesignId = "false";
    designIdloder = "false";
    ProductIdpopup = "false"
    designId = [];
    UnitDetails = [];
    unit_type_id = "";
    unit_id = "";
    ProductOptions = [];
    productDetail = [];
    shop = [];
    productSpecificationDetail = [];
    productOptionDetail = [];
    optionDetail = [];
    optionslist = [];
    productIdentifier = "";
    prodcutwarranty = "";
    YouTubevideo = "";
    model = "";
    warranty_type = "";
    minimumprice = "";
    code_for_all_variant = "";
    defaultImageDetail = [];
    uploaded_file = "";
    brandname = "";
    shop_id = "";
    productName = "";
    product_code = "";
    cod_available = "false";
    product_featured = "";
    cod = "";
    product_approved = "false";
    is_active = "false";
    ProductValues = [];
    producttype = "0";
    productcatgory = "";
    code_for_all_variant = "";
    ativate = "";
    content = "";
    Brand = [];
    parentId = null;
    productValueid = [];
    All_categories = [];
    filterSearch = "";
    PaginationPage = "1";
    PaginationPageDesginId = "1";
    TotalPages = "";
    TotalDeginPages = "";
    designIdTotalPages = "";
    BannerDimiensions = ""
    crop = {};
    errLoadProductCatalog = "";
    LoadProductCatalog = false;
    delateLoader = false;
    nolist = false;
    ProductCatalogpopup = false;
    deletepopup = false;
    ProductCatalogname = "";
    status = "1";
    designIdstatus = "";
    statesid = "";
    ProductCatalogid = "";
    seoUrl = "";
    statuspopup = false;
    addProductCatalogname = "";
    imageurl = "";
    logoimage = [];
    bannerimage = "";
    Base64logoImage = "";
    Base64BannerImage = "";
    logofile = [];
    allProductCatalog = [];
    alldeactivate = 'false';
    isBannerUpdated = "false";
    isLogoUpdated = "false"
    checkeddd = '';
    ProductCatalogFormDetails = {
        "name": "",
        "seo_url": "",
        "logo_image": "",
        "banner_image": "",
        "is_active": ""
    };
    saved = false;
    BASE_URL = process.env.REACT_APP_BASE_URL;

    constructor(isloading = false) {
        makeAutoObservable(this, {
            // addProductCatalogname: observable,
            status: observable,
            errorMessages: observable,
            setaddProductCatalogname: action,
            getstatus: action,
            resetErrors: action,
        });

        this.Token = localStorage.getItem("Admintoken");
        if (isloading.isloading) {
            this.getFilterProductCatalog();
            // this.getProductCatalogList();

            //   this.getStateList();
            return
        }
        if (isloading.addProductCataloglist) {
            //   this.getStateList();
            this.getAllCategoriesList();
            this.getrankList();
            this.getUnitDetails();
            // this.getBrandList();
            // this.getshop();
            this.getProductOptionsList();
        }

        if (isloading.editProductCataloglist) {
            this.getProductCatalogform(isloading.id);
            this.getUnitDetails();
            this.getrankList();

            this.getProductOptionsList();
            this.getAllCategoriesList();
            return
        }
    }
    //All Clear 
    allclear = () => {
        this.filterSearch = ""
        this?.getFilterProductCatalog();
        //   this.status="";
        //   this.statesid="";
    }


    /////////Add Product

    setproducttype = (text) => {
        this.producttype = text
    };

    isBase64 = (str) => {
        try {
            return btoa(atob(str)) === str;
        } catch (err) {
            return false;
        }
    };

    setproductcatgory = (text) => {
        this.productcatgory = text
    };
    setproductIdentifier = (text) => {
        this.productIdentifier = text
    };
    setproductName = (text) => {
        this.productName = text
    };
    getunit_type_idss = (text) => {
        this.unit_type_id = text
    }
    getunit_id = (text) => {
        this.unit_id = text
    }
    setBrandName = (text) => {
        console.log("setBrandName======================>>>>>>>>>>>>>>>>>>>>>>>>>>", text);

        this.brandname = text
    };
    setshop = (text) => {
        console.log("setBrandName======================>>>>>>>>>>>>>>>>>>>>>>>>>>", text);

        this.shop_id = text
    };

    setCategory = (text) => {
        console.log("setCategory======================>>>>>>>>>>>>>>>>>>>>>>>>>>", text);
        this.Category = text
    };
    setmodel = (text) => {
        this.model = text
    };
    setVideotitle = (text) => {
        this.Videotitle = text
    };
    setvideo_desc = (text) => {
        this.video_desc = text
    };
    setminimumprice = (text) => {
        this.minimumprice = text
    };
    setgst = (text) => {
        this.gst = text
    };
    sethsn = (text) => {
        this.hsn = text
    };
    setRank = (text) => {
        this.Rank = text
    }
    setprodcutwarranty = (text) => {
        this.prodcutwarranty = text
    };
    setwarranty_type = (text) => {
        this.warranty_type = text
    };
    setYouTubevideo = (text) => {

        this.YouTubevideo = text
    };
    // setDiscription= (text) => {
    //     this.Discription = text
    // };

    getcontent = (text) => {
        console.log("content==========>>>>>>>>>>>", text);
        this.content = text;
    }
    setkeywords = (text) => {
        this.keywords = text
    };
    getoptions_in_combination = (text) => {
        console.log("content==========>>>>>>>>>>>", text);
        this.options_in_combination = text;
    }


    setcod_available = (text) => {
        this.cod_available = !this.cod_available
    };
    setproduct_featured = (text) => {
        this.product_featured = !this.product_featured

        console.log("this.product_featured", this.product_featured);
    };
    setcod = (text) => {
        this.cod = !this.cod


    };
    setproduct_approved = (text) => {
        this.product_approved = !this.product_approved
    };
    setis_active = (text) => {
        this.is_active = !this.is_active
    };
    setproduct_code = (text) => {
        this.product_code = text;
    }

    ////////////////////












    setparentId = (text) => {
        this.parentId = text

    };
    setalldeactivate = (text) => {
        this.alldeactivate = !this.alldeactivate;
    };

    getid = (text) => {
        this.ProductCatalogid = text;
    };
    setproductValueid = (text) => {
        this.productValueid = text;
    };

    // status popup
    setstatuspopup = () => {
        this.statuspopup = !this.statuspopup;
    };


    setallProductCatalog = (text) => {
        this.allProductCatalog = text;
    }

    // get status 
    getstatus = (text) => {
        this.status = text;
        console.log("texttttttttttttttttttttttttttttt", text);
    };
    getdesignIdstatus = (text) => {
        this.designIdstatus = text;
        console.log("texttttttttttttttttttttttttttttt", text);
    };

    //get city name
    getProductCatalogname = (text) => {
        this.ProductCatalogname = text;
    };

    setaddProductCatalogname = (text) => {
        this.addProductCatalogname = text;
    };
    setCrop = (text) => {
        this.crop = text;
    };

    setBannerDimiensions = (text) => {
        this.BannerDimiensions = text;
    };

    setlogofile = (e) => {
        this.logofile = e;
    };

    setProductCatalogpopup = (e) => {
        this.ProductCatalogpopup = e;
    };
    setdeletepopup = (e) => {
        this.deletepopup = e;
    };
    setdelateLoader = (e) => {
        this.delateLoader = e;
    }
    getToken = (e) => {
        this.Token = e;
    }

    getseourl = (text) => {
        // console.log(`https://demo.e-kart.com/${text}`);
        this.seoUrl = text;
    };
    getlogoimage = (text) => {
        console.log("text==========>>>>>>>>>>>>>", text);
        this.logoimage = text;
    };
    getbanner_image = (text) => {
        this.bannerimage = text;
    };
    SetfilterSearch = (text) => {
        this.filterSearch = text;
    };
    setPaginationPage = (text) => {
        this.PaginationPage = text;
    };
    setPaginationDesginIdPage = (text) => {
        this.PaginationPageDesginId = text;
    };
    SetTotalDeginPages = (text) => {
        this.TotalDeginPages = text;
    };
    SetTotalPages = (text) => {
        this.TotalPages = text;
    };

    setdesignIdTotalPages = (text) => {
        this.designIdTotalPages = text;
    };
    setshipping = (text) => {
        this.shipping = text;
    };
    setchecked = (text) => {
        this.checkeddd = text;
    };
    setisBannerUpdated = (text) => {
        this.isBannerUpdated = text;
    };
    setisLogoUpdated = (text) => {
        this.isLogoUpdated = text;
    };
    setuploaded_file = (text) => {
        this.uploaded_file = text;
    };
    setmaster_option = (text) => {
        this.master_option = text;
    };

    setcode_for_all_variant = (text) => {
        this.code_for_all_variant = !this.code_for_all_variant
    };
    setativate = (text) => {
        this.ativate = !this.ativate;
    };
    setProductIdpopup = (e) => {
        this.ProductIdpopup = e;
    };
    setoption = (e) => {
        this.option = e;
    };
    setdesign_id = (e) => {
        this.design_id = e;
    };
    isBase64 = (str) => {
        try {
            return btoa(atob(str)) === str;
        } catch (err) {
            return false;
        }
    };

    setProductCatalogFormDetails = (ProductCatalogDetail) => {
        this.ProductCatalogFormDetails = {
            ...this.ProductCatalogFormDetails,
            name: ProductCatalogDetail.name || "", // Use default value if name is undefined
            seo_url: ProductCatalogDetail.seo_url || "", // Use default value if seo_url is undefined
            // logo_image: this.isBase64( this.ProductCatalogFormDetails.logo_image) ? this.logofile.length == 0 ? ProductCatalogDetail.logo_image    : this.ProductCatalogFormDetails.logo_image  : this.logofile.length !== 0 ? this.ProductCatalogFormDetails.logo_image :  ProductCatalogDetail.logo_image   || "",
            // banner_image: this.isBase64(ProductCatalogDetail.banner_image) ? this.ProductCatalogFormDetails.banner_image : ProductCatalogDetail.banner_image || "",
            logo_image: ProductCatalogDetail.logo_image || "",
            banner_image: ProductCatalogDetail.banner_image || "",
            is_active: ProductCatalogDetail.is_active
        };
    };
    // setProductCatalogFormDetails = (text) => {
    //     this.ProductCatalogFormDetails = text;
    //     console.log("setProductCatalogFormDetails", this.ProductCatalogFormDetails.ProductCatalogname);
    // };
    base64toFile(base64Data, fileName, mimeType) {
        if (base64Data) {
            const byteCharacters = atob(base64Data);
            const byteArrays = [];

            for (let offset = 0; offset < byteCharacters.length; offset += 512) {
                const slice = byteCharacters.slice(offset, offset + 512);

                const byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }

                const byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
            }

            const file = new File(byteArrays, fileName, { type: mimeType });

            return file;
        }
    }
    getshop = () => {
        runInAction(() => {
            this.ProductCatalogLoader = true;
            this.errLoadProductCatalog = "";
            this.saved = false;
            this.nolist = false;
            this.shop = [];
        });
        getMethodData(`/api/v1/admin/products/get-shops`, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.ProductCatalogLoader = false;
                        this.errLoadProductCatalog = resp?.data?.message
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.ProductCatalogLoader = false;
                        this.errLoadProductCatalog = resp?.data?.message;
                        if (resp?.data?.data.length === 0) {
                            this.nolist = true;
                        } else {
                            this.nolist = false;
                        }
                        this.shop = resp?.data?.data;
                        this.SetTotalPages(resp?.data?.totalPage)
                        this.saved = false;
                        // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.LoadProductCatalog = false;
                    this.errLoadProductCatalog = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.ProductCatalogLoader = false;
                    if (!error.response) {
                        this.errLoadProductCatalog = error?.message;
                        toast.error(error?.message);
                    }
                    this.errLoadProductCatalog = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };
    getrankList = () => {
        runInAction(() => {
            this.CategoriesLoader = true;
            this.errCategories = "";
            this.saved = false;
            this.nolist = false;
        });
        getMethodData(`/api/v1/admin/products-rank`, this.Token)
            .then((resp) => {

                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.CategoriesLoader = false;
                        this.errCategories
                            = resp?.data?.message
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.CategoriesLoader = false;

                        this.rankList = resp?.data?.data;
                        console.log("rankkkkkkkkkkkkkkkkkk", this.rankList);

                        this.saved = false;
                        // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.LoadCategories = false;
                    this.errCategories
                        = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.CategoriesLoader = false;
                    if (!error.response) {
                        this.errCategories
                            = error?.message;
                        toast.error(error?.message);
                    }
                    this.errCategories
                        = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };
    // get ProductCatalog list
    getProductCatalogList = () => {
        runInAction(() => {
            this.ProductCatalogLoader = true;
            this.errLoadProductCatalog = "";
            this.saved = false;
            this.nolist = false;
            this.ProductCatalog = [];
        });
        getMethodData(`/api/v1/admin/products/get-list?page=${this.PaginationPage}`, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.ProductCatalogLoader = false;
                        this.errLoadProductCatalog = resp?.data?.message
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.ProductCatalogLoader = false;
                        this.errLoadProductCatalog = resp?.data?.message;
                        if (resp?.data?.data.length === 0) {
                            this.nolist = true;
                        } else {
                            this.nolist = false;
                        }
                        this.ProductCatalog = resp?.data?.data;
                        this.SetTotalPages(resp?.data?.totalPage)
                        this.saved = false;
                        // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.LoadProductCatalog = false;
                    this.errLoadProductCatalog = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.ProductCatalogLoader = false;
                    if (!error.response) {
                        this.errLoadProductCatalog = error?.message;
                        toast.error(error?.message, {
                            autoClose: 5000, // Set the duration to 5000ms (5 seconds)
                        });
                    }
                    this.errLoadProductCatalog = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message, {
                        autoClose: 5000, // Set the duration to 5000ms (5 seconds)
                    });
                })
                return;
            });
    };

    getPaginationDesginList = () => {
        runInAction(() => {
            this.designIdloder = true;
            this.errLoadProductCatalog = "";
            this.saved = false;
            this.nolist = false;

        });

        getMethodData(`/api/v1/admin/products-design/get-list/${this.ProductCatalogid}?page=${this.PaginationPageDesginId}&limit=10`, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.designIdloder = false;
                        this.errLoadProductCatalog = resp?.data?.message
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.designIdloder = false;
                        this.errLoadProductCatalog = resp?.data?.message;
                        if (resp?.data?.data.length === 0) {
                            this.nolist = true;
                        } else {
                            this.nolist = false;
                        }
                        this.designId = resp?.data?.data;

                        this.SetTotalDeginPages(resp?.data?.totalPage)
                        this.saved = false;
                        // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.LoadProductCatalog = false;
                    this.errLoadProductCatalog = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.designIdloder = false;
                    if (!error.response) {
                        this.errLoadProductCatalog = error?.message;
                        // toast.error(error?.message, {
                        //     autoClose: 5000, // Set the duration to 5000ms (5 seconds)
                        // });
                    }
                    this.errLoadProductCatalog = error?.response?.data?.message;
                    // toast.error(error?.response?.data?.message, {
                    //     autoClose: 5000, // Set the duration to 5000ms (5 seconds)
                    // });
                })
                return;
            });
    };
    //Filter city
    // filter League court
    // get root categories list
    getAllCategoriesList = () => {
        runInAction(() => {
            this.ProductCatalogLoader = true;
            this.errLoadProductCatalog = "";
            this.saved = false;
            this.nolist = false;
            this.All_categories = [];
        });
        getMethodData(`/api/v1/admin/products/get-product-category`, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.ProductCatalogLoader = false;
                        this.errLoadProductCatalog
                            = resp?.data?.message
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.ProductCatalogLoader = false;
                        this.errLoadProductCatalog
                            = resp?.data?.message;
                        if (resp?.data?.data.length === 0) {
                            this.nolist = true;
                        } else {
                            this.nolist = false;
                        }
                        this.All_categories = resp?.data?.data;
                        this.SetTotalPages(resp?.data?.totalPage)
                        this.saved = false;
                        // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.LoadCategories = false;
                    this.errLoadProductCatalog
                        = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.ProductCatalogLoader = false;
                    if (!error.response) {
                        this.errLoadProductCatalog
                            = error?.message;
                        toast.error(error?.message, {
                            autoClose: 5000, // Set the duration to 5000ms (5 seconds)
                        });
                    }
                    this.errLoadProductCatalog
                        = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message, {
                        autoClose: 5000, // Set the duration to 5000ms (5 seconds)
                    });
                })
                return;
            });
    };
    // get brand list
    getBrandList = () => {
        runInAction(() => {
            this.ProductCatalogLoader = true;
            this.errLoadProductCatalog = "";
            this.saved = false;
            this.nolist = false;
            this.Brand = [];
        });
        getMethodData(`/api/v1/admin/brand/fetch`, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.ProductCatalogLoader = false;
                        this.errLoadProductCatalog = resp?.data?.message
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.ProductCatalogLoader = false;
                        this.errLoadProductCatalog = resp?.data?.message;
                        if (resp?.data?.data.length === 0) {
                            this.nolist = true;
                        } else {
                            this.nolist = false;
                        }
                        this.Brand = resp?.data?.data;
                        this.SetTotalPages(resp?.data?.totalPage)
                        this.saved = false;
                        // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.LoadBrand = false;
                    this.errLoadProductCatalog = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.ProductCatalogLoader = false;
                    if (!error.response) {
                        this.errLoadProductCatalog = error?.message;
                        toast.error(error?.message);
                    }
                    this.errLoadProductCatalog = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };

    getFilterProductCatalog = () => {
        runInAction(() => {
            this.ProductCatalogLoader = true;
            this.errLoadProductCatalog = "";
            this.saved = false;
            this.nolist = false;
            this.getProductCatalogList = [];
        });
        postMethodData(`/api/v1/admin/products/search?page=${this.PaginationPage}&limit=10`, {
            keyword: this.filterSearch

        }, this.Token)
            .then((resp) => {

                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.loadleague = false;
                        this.ProductCatalogLoader = false;

                        this.errloadleague = resp?.data?.message
                    });
                    return;
                }
                if (resp?.data?.status === true) {

                    runInAction(() => {
                        this.loadcity = false;
                        this.ProductCatalogLoader = false;

                        this.errloadleague = resp?.data?.message;
                        if (resp?.data?.data.length === 0) {
                            this.nolist = true;
                        } else {
                            this.nolist = false;
                        }
                        this.ProductCatalog = resp?.data?.data;
                        this.SetTotalPages(resp?.data?.totalPage)
                        this.saved = false;
                        // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.loadcity = false;
                    this.errloadcuty = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.loadleague = false;
                    this.ProductCatalogLoader = false;

                    if (!error.response) {
                        this.errloadleague = error?.message;
                        toast.error(error?.message, {
                            autoClose: 5000, // Set the duration to 5000ms (5 seconds)
                        });
                    }
                    this.errloadleague = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message, {
                        autoClose: 5000, // Set the duration to 5000ms (5 seconds)
                    });
                })
                return;
            });
    };

    // add new ProductCatalog
    addProductCatalog = (product_options, specificatios, selectedValues, combined_options, id, processedFrameImages, frame_desc) => {
        runInAction(() => {
            this.addloadProductCatalog = true;
            this.errLoadProductCatalog = "";
        });
        const formData = new FormData();

        const strippedContent = this.content
        const videoContent = this.video_desc.replace(/<[^>]*>/g, '');
        const keywordContent = this.keywords.replace(/<[^>]*>/g, '');

        console.log("specificatios", specificatios);


        // Iterate over each base64 image in selectedValues
        selectedValues.forEach((base64String, index) => {
            console.log("base65Imageeeeeeeeeeeeeeeeeeeeeeeeeeeeeee", base64String);
            const fileName = `image_${index}.png`; // Adjust filename as needed
            const logoFile = this.base64toFile(base64String.split(',')[1], fileName, 'image/png');
            formData.append(`image_${index}`, logoFile);
        });
        processedFrameImages.forEach((base64String, index) => {
            console.log("base65Imageeeeeeeeeeeeeeeeeeeeeeeeeeeeeee", base64String);
            const fileName = `frame_${index}.png`; // Adjust filename as needed
            const logoFile = this.base64toFile(base64String.split(',')[1], fileName, 'image/png');
            formData.append(`frame_${index}`, logoFile);
        });



        // Append the array of image files to the FormData object under the key 'image'
        formData.append('status', this.ativate ? 1 : 0);
        formData.append('is_featured', this.product_featured ? 1 : 0);
        formData.append('can_cod', this.cod ? 1 : 0);
        formData.append('product_name', this.productName);
        formData.append('tax', this.gst);
        if (this.Rank) {
            formData.append('rank', this.Rank);
        }
        formData.append('hsn', this.hsn);
        formData.append('category_id', id || this.Category);
        formData.append('shipping', this.shipping);
        formData.append('video_title', this.Videotitle);
        formData.append('keywords', keywordContent);
        formData.append('video_desc', videoContent);
        formData.append('frame_desc', JSON.stringify(frame_desc));


        formData.append('stock_qty', this.minimumprice);
        formData.append('stock_unit', this.unit_id);
        formData.append('description', strippedContent);
        formData.append('url', this.YouTubevideo);
        formData.append('default_costing', JSON.stringify(specificatios));
        formData.append('has_options', this.producttype);
        formData.append('stock_unit_type', this.unit_type_id);
        if (this.option == 1) {
            formData.append('master_option', product_options.flat()[0].option_id);
        }

        formData.append('is_combined_option', this.option == 0 ? 1 : 0);

        if (this.producttype == 1) {
            if (this.option == 0) {
                formData.append('combined_options', JSON.stringify(combined_options.flat()));
            }
            else {
                formData.append('product_options', JSON.stringify(product_options.flat()));
            }


        }
        else {
            formData.append('combined_options', JSON.stringify([]));
            formData.append('product_options', JSON.stringify([]));

        }
        if (specificatios.length == 0 && this.producttype == 0) {
            toast.error("Please click on Add button to submit coasting and quantity of your product", {
                autoClose: 5000, // Set the duration to 5000ms (5 seconds)
            });
            this.addloadProductCatalog = false;
            return;
        }
        else {
            this.addloadProductCatalog = true;

        }

        if (selectedValues.length == 0) {
            toast.error("Please upload atlest one image related to product", {
                autoClose: 5000, // Set the duration to 5000ms (5 seconds)
            });
            this.addloadProductCatalog = false;
            return;
        }
        else {
            this.addloadProductCatalog = true;

        }
        if (!this.YouTubevideo || !this.YouTubevideo.startsWith("https://")) {
            toast.error("Please provide a valid URL starting with 'https://'");
            this.addloadProductCatalog = false;
            return;
        }
        else {
            this.addloadProductCatalog = true;

        }





        postMethodData("/api/v1/admin/products/add", formData, this.Token).then((resp) => {
            if (resp?.data?.status === false) {

                runInAction(() => {
                    this.addloadProductCatalog = false;
                    this.errLoadProductCatalog = resp?.data?.message;
                    toast.error(resp?.data?.formErrors?.variantImage || resp?.data?.formErrors?.defaultImage || resp?.data?.message || resp?.data?.image);
                });
                return;
            }
            if (resp?.data?.status === true) {
                runInAction(() => {
                    this.addloadProductCatalog = false;
                    this.errLoadProductCatalog = resp?.data?.message;
                    this.getFilterProductCatalog();
                    this.saved = true;
                });
                return;
            }
            runInAction(() => {
                this.addloadProductCatalog = false;
                this.errLoadProductCatalog = resp?.data?.message;
            });
        }).catch((error) => {
            runInAction(() => {
                this.addloadProductCatalog = false;
                console.log("errororrrrrrrrrrrrrrrMesaheeeeeeeeeeeee", error.response.data.image);
                if (!error.response) {
                    this.errLoadProductCatalog = error?.message;
                    toast.error(error?.message);
                }
                this.errLoadProductCatalog = error?.response?.data?.message;
                const formErrors = error.response.data.formErrors;
                (formErrors &&
                    Object.keys(formErrors)?.forEach((key) => {
                        const errorMessage = formErrors[key];
                        toast.error(`${key}: ${errorMessage}`, {
                            autoClose: 5000, // Set the duration to 5000ms (5 seconds)
                        });
                    }))
                toast.error(error?.response?.data?.image, {
                    autoClose: 5000, // Set the duration to 5000ms (5 seconds)
                });


                // toast.error(error?.response?.data?.formErrors?.variantImage || error?.response?.data?.formErrors?.defaultImage || error?.response?.data?.message);
            });
        });
    };

    UpdateProductCatalog = (product_options, specificationsList, combined_options, selectedValues, id, processedFrameImages, frameDescriptions, NewFrameDescIndex) => {
        runInAction(() => {
            this.editloadProductCatalog = true;
            this.errLoadProductCatalog = "";
        });

        // Check if any object in selectedValues has the key "check" with value "default"
        const hasDefaultCheck = selectedValues.some(value => value.check === "default");
        const strippedContent = this.content
        const videoContent = this.video_desc?.replace(/<[^>]*>/g, '');
        const keywordContent = this.keywords?.replace(/<[^>]*>/g, '');
        const formData = new FormData();
        // Filter out the object with "default" check value
        const filteredSelectedValues = selectedValues.filter(value => value.check !== "default");
        console.log("combined_options55555555555", combined_options);

        selectedValues.forEach((base64String, index) => {

            const fileName = `image_${index}.png`; // Adjust filename as needed
            const logoFile = this.base64toFile(base64String.split(',')[1], fileName, 'image/png');
            formData.append(`image_${index}`, logoFile);
        });
        processedFrameImages.forEach((base64String, index) => {
            console.log("NewFrameDescIndex + index", NewFrameDescIndex + index);
            const fileName = `frame_${NewFrameDescIndex + index}.png`; // Adjust filename as needed
            const logoFile = this.base64toFile(base64String.split(',')[1], fileName, 'image/png');
            formData.append(`frame_${NewFrameDescIndex + index}`, logoFile);
        });
        console.log("image_name_array", this.image_name_array.length);
        formData.append('product_name', this.productName);
        formData.append('shipping', this.shipping);
        formData.append('video_title', this.Videotitle);
        formData.append('video_desc', videoContent);
        formData.append('keywords', keywordContent);
        formData.append('category_id', this.Category);
        formData.append('tax', this.gst);
        if (this.Rank) {
            formData.append('rank', this.Rank);
        }
        formData.append('hsn', this.hsn);
        formData.append('stock_qty', this.minimumprice);
        formData.append('status', this.ativate ? 1 : 0);
        formData.append('is_featured', this.product_featured ? 1 : 0);
        formData.append('can_cod', this.cod ? 1 : 0);
        formData.append('stock_unit', this.unit_id == "" ? this.productDetail.stock_unit : this.unit_id);
        formData.append('stock_unit_type', this.unit_type_id || this.productDetail.unit_type_id);
        formData.append('url', this.YouTubevideo);
        formData.append('frame_desc', JSON.stringify(frameDescriptions));

        formData.append('description', strippedContent);
        formData.append('product_id', id);
        formData.append('default_costing', JSON.stringify(specificationsList));

        if (this.producttype == 1) {

            if (this.optionslist.length == 0) {
                formData.append('combined_options', JSON.stringify(combined_options.flat()));

            }
            else {
                formData.append('product_options', JSON.stringify(product_options.flat()));

                formData.append('master_option', this.optionslist[0][0].option_id);

            }
        }
        if (this.producttype == 1) {
            formData.append('is_combined_option', this.optionslist.length == 0 ? 1 : 0);

        }
        if (this.producttype == 0) {
            if (specificationsList.length == 0) {
                toast.error("Please click on Add button to submit coasting and quantity of your product", {
                    autoClose: 5000, // Set the duration to 5000ms (5 seconds)
                });
                this.editloadProductCatalog = false;
                return;
            }
            else {
                this.editloadProductCatalog = true;

            }
        }


        if (this.image_name_array.length == 0 && selectedValues.length == 0) {
            toast.error("Please upload atlest one image related to product", {
                autoClose: 5000, // Set the duration to 5000ms (5 seconds)
            });
            this.editloadProductCatalog = false;
            return;
        }
        else {
            this.editloadProductCatalog = true;

        }

        if (!this.YouTubevideo || !this.YouTubevideo.startsWith("https://")) {
            toast.error("Please provide a valid URL starting with 'https://'", {
                autoClose: 5000, // Set the duration to 5000ms (5 seconds)
            });
            this.editloadProductCatalog = false;
            return;
        }
        else {
            this.editloadProductCatalog = true;

        }

        // Conditionally append product_files based on the presence of "default" check


        // Make the API call with the constructed request object
        postMethodData("/api/v1/admin/products/edit", formData, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {

                        this.editloadProductCatalog = false;
                        console.log("logggggggggggggg toooooooooooooooo product combination", resp);
                        this.errLoadProductCatalog = resp?.data?.message;
                        toast.error(resp?.data?.formErrors?.variantImage || resp?.data?.formErrors?.defaultImage || resp?.data?.formErrors?.combined_options || resp?.data?.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.editloadProductCatalog = false;

                        this.errLoadProductCatalog = resp?.data?.message;
                        this.getFilterProductCatalog();
                        this.saved = true;
                    })
                    return;
                }
                runInAction(() => {
                    this.addloadProductCatalog = false;
                    this.errLoadProductCatalog = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.editloadProductCatalog = false;

                    if (!error.response) {
                        this.errLoadProductCatalog = error?.message;
                        toast.error(error?.formErrors?.combined_options, {
                            autoClose: 5000, // Set the duration to 5000ms (5 seconds)
                        });
                    }
                    this.errLoadProductCatalog = error?.response?.data?.message;
                    toast.error(error?.response?.data?.formErrors?.variantImage || error.response.data.formErrors?.combined_options || error?.response?.data?.formErrors?.defaultImage || error?.response?.data?.message, {
                        autoClose: 5000, // Set the duration to 5000ms (5 seconds)
                    });
                    // const formErrors = error.response.data.formErrors;
                    // Object.keys(formErrors).forEach((key) => {
                    //     const errorMessage = formErrors[key];
                    //     toast.error(`${key}: ${errorMessage}`);
                    // });
                    toast.error(error?.response?.data?.image, {
                        autoClose: 5000, // Set the duration to 5000ms (5 seconds)
                    });
                });
                return;
            });
    };










    addProductImage = async (image) => {
        runInAction(() => {
            this.errLoadProductCatalog = "";
        });

        const formData = new FormData();
        if (image) {
            // const Logobase64String = image?.split(',')[1];
            // const logoFile = this.base64toFile(Logobase64String, 'logo.png', 'image/png');
            formData.append('logo_image', image);
        }

        // formData.append('youtube_video_url', this.YouTubevideo);





        try {
            const resp = await postMethodData("/api/v1/admin/products/upload-image", formData, this.Token);

            if (resp?.data?.status === false) {
                runInAction(() => {
                    // ... (unchanged code)
                });
            } else if (resp?.data?.status === true) {
                const imageName = resp?.data?.data?.logo_image.uploaded_file;
                runInAction(() => {
                    // ... (unchanged code)
                    this.setuploaded_file(imageName);
                });
                return imageName; // Returning image name
            }
        } catch (error) {
            runInAction(() => {
                // ... (unchanged code)
            });
        }
    };

    //Active and inactive 
    statusChange = () => {
        runInAction(() => {
            this.ProductCatalogLoader = true;
            this.ProductCatalogpopup = true;

            this.errLoadProductCatalog = "";
        });
        postMethodData(`/api/v1/admin/products/update-status`, {

            "product_id": this.ProductCatalogid,
            "status": JSON.stringify(this.status)

        }, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.ProductCatalogLoader = false;
                        this.ProductCatalogpopup = false;
                        this.errLoadProductCatalog = resp?.data?.message;
                        toast.error(resp?.data?.message, {
                            autoClose: 5000, // Set the duration to 5000ms (5 seconds)
                        });
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.ProductCatalogLoader = false;
                        this.ProductCatalogpopup = false;

                        this.errLoadProductCatalog = resp?.data?.message;
                        this.getFilterProductCatalog();
                        toast.success(resp?.data?.message, {
                            autoClose: 5000, // Set the duration to 5000ms (5 seconds)
                        });
                    })
                    return;
                }
                runInAction(() => {
                    this.ProductCatalogLoader = false;
                    this.ProductCatalogpopup = false;

                    this.errLoadProductCatalog = resp?.data?.message;
                })
            })
            .catch((error) => {
                runInAction(() => {
                    if (!error.response) {
                        this.ProductCatalogLoader = false;
                        this.ProductCatalogpopup = false;

                        this.errLoadProductCatalog = error?.message;
                        toast.error(error?.message, {
                            autoClose: 5000, // Set the duration to 5000ms (5 seconds)
                        });
                    }
                    toast.error(error?.response?.data?.message, {
                        autoClose: 5000, // Set the duration to 5000ms (5 seconds)
                    });
                    this.ProductCatalogLoader = false;
                    this.ProductCatalogpopup = false;

                    this.errLoadProductCatalog = error?.response?.data?.message;
                })
                return;
            })
    };
    // Bulk Active and inactive
    BulkstatusChange = (allProductCatalog) => {
        runInAction(() => {
            this.ProductCatalogLoader = true;
            this.ProductCatalogpopup = true;

            this.errLoadProductCatalog = "";
        });

        postMethodData(`/api/v1/admin/ProductCatalog/toggle-bulk-status`, {

            "branchId": JSON.stringify(allProductCatalog),
            "status": JSON.stringify(this.alldeactivate ? 1 : 0)

        }, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.ProductCatalogLoader = false;
                        this.ProductCatalogpopup = false;
                        this.errLoadProductCatalog = resp?.data?.message;
                        toast.error(resp?.data?.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.ProductCatalogLoader = false;
                        this.ProductCatalogpopup = false;

                        this.errLoadProductCatalog = resp?.data?.message;
                        this.getFilterProductCatalog();

                        toast.success(resp?.data?.message);
                    })
                    return;
                }
                runInAction(() => {
                    this.ProductCatalogLoader = false;
                    this.ProductCatalogpopup = false;

                    this.errLoadProductCatalog = resp?.data?.message;
                })
            })
            .catch((error) => {
                runInAction(() => {
                    if (!error.response) {
                        this.ProductCatalogLoader = false;
                        this.ProductCatalogpopup = false;

                        this.errLoadProductCatalog = error?.message;
                        toast.error(error?.message);
                    }
                    toast.error(error?.response?.data?.message);
                    this.ProductCatalogLoader = false;
                    this.ProductCatalogpopup = false;

                    this.errLoadProductCatalog = error?.response?.data?.message;
                })
                return;
            })
    };
    //Delete image
    deleteImagefunction = (img_name, id) => {
        console.log("imageeeeeeeeeeeeeenameeeeeeeeeeee", img_name);
        runInAction(() => {
            this.setdelateLoader(true);
            this.deletepopup = true;
            this.errLoadProductCatalog = "";
        });

        postMethodData(`/api/v1/admin/products/delete-image`, {

            "img_name": img_name,
            "product_id": id


        }, this.Token).then((resp) => {
            if (resp?.data?.status === false) {
                runInAction(() => {
                    this.LoadProductCatalog = false;
                    this.setdelateLoader(true);
                    this.deletepopup = true;
                    this.errLoadProductCatalog = resp?.data?.message;
                    toast.error(resp.data.message);
                });
                return;
            }
            if (resp?.data?.status === true) {
                runInAction(() => {
                    this.LoadProductCatalog = false;
                    this.setdelateLoader(false);
                    this.deletepopup = false;
                    console.log("resp?.data?.img_arr", resp?.data?.img_arr);
                    this.errLoadProductCatalog = resp?.data?.message;
                    this.frame_name_array = resp?.data?.img_arr;
                    this.getFilterProductCatalog();

                    // toast.success(resp.data.message);
                })
                return;
            }
            runInAction(() => {
                this.LoadProductCatalog = false;
                this.errLoadProductCatalog = resp?.data?.message;
            });

        })
            .catch((error) => {
                runInAction(() => {
                    this.addloadcity = false;
                    if (!error.response) {
                        this.LoadProductCatalog = false;
                        this.setdelateLoader(false);
                        this.deletepopup = false;
                        this.errLoadProductCatalog = error?.message;
                        toast.error(error?.message);
                    }
                    this.LoadProductCatalog = false;
                    this.errLoadProductCatalog = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            })
    }

    //Delete method
    deletefunction = () => {
        runInAction(() => {
            this.setdelateLoader(true);
            this.deletepopup = true;
            this.errLoadProductCatalog = "";
        });
        postMethodData(`/api/v1/admin/products/delete`, {

            "product_id": this.ProductCatalogid,

        }, this.Token).then((resp) => {
            if (resp?.data?.status === false) {
                runInAction(() => {
                    this.LoadProductCatalog = false;
                    this.setdelateLoader(true);
                    this.deletepopup = true;
                    this.errLoadProductCatalog = resp?.data?.message;
                    toast.error(resp.data.message);
                });
                return;
            }
            if (resp?.data?.status === true) {
                runInAction(() => {
                    this.LoadProductCatalog = false;
                    this.setdelateLoader(false);
                    this.deletepopup = false;
                    this.errLoadProductCatalog = resp?.data?.message;
                    this.getFilterProductCatalog();
                    // toast.success(resp.data.message);
                })
                return;
            }
            runInAction(() => {
                this.LoadProductCatalog = false;
                this.errLoadProductCatalog = resp?.data?.message;
            });

        })
            .catch((error) => {
                runInAction(() => {
                    this.addloadcity = false;
                    if (!error.response) {
                        this.LoadProductCatalog = false;
                        this.setdelateLoader(false);
                        this.deletepopup = false;
                        this.errLoadProductCatalog = error?.message;
                        toast.error(error?.message);
                    }
                    this.LoadProductCatalog = false;
                    this.setdelateLoader(false);
                    this.errLoadProductCatalog = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            })
    }
    // Bulk Delete 
    BulkDelete = (allProductCatalog) => {
        runInAction(() => {
            this.ProductCatalogLoader = true;
            this.ProductCatalogpopup = true;

            this.errLoadProductCatalog = "";
        });

        postMethodData(`/api/v1/admin/ProductCatalog/multi-delete`, {

            "ProductCatalogId": JSON.stringify(allProductCatalog),
            // "status": JSON.stringify(this.alldeactivate ? 1 : 0)

        }, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.ProductCatalogLoader = false;
                        this.ProductCatalogpopup = false;
                        this.errLoadProductCatalog = resp?.data?.message;
                        toast.error(resp?.data?.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.ProductCatalogLoader = false;
                        this.ProductCatalogpopup = false;

                        this.errLoadProductCatalog = resp?.data?.message;
                        this.getFilterProductCatalog();
                        toast.success(resp?.data?.message);
                    })
                    return;
                }
                runInAction(() => {
                    this.ProductCatalogLoader = false;
                    this.ProductCatalogpopup = false;

                    this.errLoadProductCatalog = resp?.data?.message;
                })
            })
            .catch((error) => {
                runInAction(() => {
                    if (!error.response) {
                        this.ProductCatalogLoader = false;
                        this.ProductCatalogpopup = false;

                        this.errLoadProductCatalog = error?.message;
                        toast.error(error?.message);
                    }
                    toast.error(error?.response?.data?.message);
                    this.ProductCatalogLoader = false;
                    this.ProductCatalogpopup = false;

                    this.errLoadProductCatalog = error?.response?.data?.message;
                })
                return;
            })
    };
    //Delete sepecification
    deleteSpecification = (id, category_id) => {
        runInAction(() => {
            this.setdelateLoader(true);
            this.deletepopup = true;
            this.errLoadProductCatalog = "";
        });
        postMethodData(`/api/v1/admin/products/delete-prod-spec`, {

            "record_id": category_id,
            "prod_spec_id": id

        }, this.Token).then((resp) => {
            if (resp?.data?.status === false) {
                runInAction(() => {
                    this.LoadProductCatalog = false;
                    this.setdelateLoader(true);
                    this.deletepopup = true;
                    this.errLoadProductCatalog = resp?.data?.message;
                    toast.error(resp.data.message);
                });
                return;
            }
            if (resp?.data?.status === true) {
                runInAction(() => {
                    this.LoadProductCatalog = false;
                    this.setdelateLoader(false);
                    this.deletepopup = false;
                    this.errLoadProductCatalog = resp?.data?.message;
                    this.getProductCatalogList();
                    // toast.success(resp.data.message);
                })
                return;
            }
            runInAction(() => {
                this.LoadProductCatalog = false;
                this.errLoadProductCatalog = resp?.data?.message;
            });

        })
            .catch((error) => {
                runInAction(() => {
                    this.addloadcity = false;
                    if (!error.response) {
                        this.LoadProductCatalog = false;
                        this.setdelateLoader(false);
                        this.deletepopup = false;
                        this.errLoadProductCatalog = error?.message;
                        toast.error(error?.message);
                    }
                    this.LoadProductCatalog = false;
                    this.setdelateLoader(false);
                    this.errLoadProductCatalog = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            })
    }
    //get ProductCatalog by id
    getProductCatalogform = async (id) => {
        runInAction(() => {
            this.ProductFormLoader = true;
            this.ProductCatalogpopup = true;
            this.errLoadProductCatalog = "";
        });

        try {
            const resp = await postMethodData(`/api/v1/admin/products/form`, {
                "product_id": id,
            }, this.Token);

            if (resp?.data?.status === false) {
                runInAction(() => {
                    this.ProductFormLoader = false;
                    this.ProductCatalogpopup = false;
                    this.errLoadProductCatalog = resp?.data?.message;
                });
                return;
            }

            const optionObject = resp?.data?.productDetail?.optionDetail?.map((item, index) => ([{
                option_id: item?.option_id,
                option_value_id: item?.option_value_id,
                material_consume: item?.material_consume,
                material_consume_unit: item?.material_consume_unit,
                option_name: item?.option_name,
                unit_type_id: item?.unit_type_id,
                unit_name: item?.unit_name,
                option_value_name: item?.option_value_name,
                available_options: item?.available_options,
                costing: item.option_costing.map(cost => ({ quantity: cost.quantity, price: cost.price }))
            }]));

            this.ProductFormLoader = false;

            const CombinedoptionObject = resp?.data?.productDetail?.combined_option_data?.map((item, index) => ({
                material_consume: item.material_consume,
                material_consume_unit: item.material_consume_unit,
                options_detail: item.options_detail,
                stock: item.stock,
                price: item.price,
                option_set: item.option_set // Directly assign item.option_set
            }));
            console.log("resp?.data?.productDetail?.combined_option_data", CombinedoptionObject);
            if (resp?.data?.status === true) {

                const spec = resp?.data?.productDetail.product_costing?.map((item, index) => {

                    console.log("optionDetail", item);

                    return {




                        quantity: item.quantity,

                        price: item.price,








                    };

                });
                const defaultImagePromises = resp.data?.productDetail.image?.map(async item => {


                    if (item) {
                        try {
                            const response = await fetch( item?.includes("cloudinary") ? item : `${this.BASE_URL}/uploads/product/${item}`);
                       
                            const blob = await response.blob();
                            const base64data = await new Promise((resolve, reject) => {
                                const reader = new FileReader();
                                reader.readAsDataURL(blob);
                                reader.onloadend = () => resolve(reader?.result);
                                reader.onerror = reject;
                            });
                          
                            return {   
                                uid: item?.id,
                                thumbUrl: base64data,
                                status: "done"
                            };
                        } catch (error) {
                            console.error('Error fetching image or converting to base64:', error);
                            this.ProductFormLoader = false;

                            // Handle the error here instead of throwing it
                            // For example, you can return a default object or null
                            return null;
                        }
                    }
                });

                const defaultFrameImagePromises = resp.data?.productDetail.frame_image?.map(async item => {


                    if (item) {
                        try {
                            const response = await fetch(`${item?.frame?.includes("cloudinary") ? item?.frame : this.BASE_URL}/uploads/product/${item?.frame}`);
                            const blob = await response.blob();
                            const base64data = await new Promise((resolve, reject) => {
                                const reader = new FileReader();
                                reader.readAsDataURL(blob);
                                reader.onloadend = () => resolve(reader?.result);
                                reader.onerror = reject;
                            });

                            return {
                                uid: item?.id,
                                thumbUrl: base64data,
                                status: "done"
                            };
                        } catch (error) {
                            console.error('Error fetching image or converting to base64:', error);
                            this.ProductFormLoader = false;

                            // Handle the error here instead of throwing it
                            // For example, you can return a default object or null
                            return null;
                        }
                    }
                });

                const [defaultImageDetail] = await Promise?.all([Promise.all(defaultImagePromises)]);
                const [defaultFrameImageDetail] = await Promise?.all([Promise.all(defaultFrameImagePromises)]);




                runInAction(() => {
                    console.log("respppppppppppppppppppp", defaultImagePromises);
                    this.ProductCatalogLoader = false;
                    this.ProductCatalogpopup = false;
                    this.productDetail = resp.data.productDetail;
                    this.setminimumprice(resp.data.productDetail?.stock_qty);
                    this.productSpecificationDetail = resp.data.productSpecificationDetail;
                    this.productOptionDetail = resp?.data?.productOptionDetail;
                    // this.code_for_all_variant = resp.data.productDetail.code_for_all_variant;
                    this.ativate = resp.data.productDetail?.is_active;
                    this.product_featured = resp.data.productDetail?.is_featured;
                    this.cod = resp.data.productDetail?.can_cod;
                    // this.cod_available = resp.data.productDetail.cod_available;
                    // this.product_featured = resp.data.productDetail.is_featured;
                    // this.product_approved = resp.data.productDetail.is_verified;
                    // this.is_active = resp.data.productDetail.is_active;
                    this.image_array = defaultImageDetail;
                    this.frame_image = defaultFrameImageDetail;
                    this.image_name_array = resp.data?.productDetail.image;
                    this.frame_name_array = resp.data?.productDetail.frame_image;
                    this.productName = resp.data.productDetail.name;
                    this.shipping = resp.data.productDetail.shipping_fees;
                    this.optionslist = optionObject;
                    this.combined_option_data = CombinedoptionObject;
                    this.YouTubevideo = resp.data?.productDetail?.url;
                    this.producttype = resp.data.productDetail.has_options;
                    this.content = resp.data.productDetail.description;
                    this.specificationDetail = spec;
                    this.options_in_combination = resp.data.productDetail.options_in_combination
                    this.Category = resp.data.productDetail?.category_id;
                    this.options_dropdown = resp.data.productDetail.options_dropdown;
                    this.Videotitle = resp.data.productDetail.title;
                    this.hsn = resp.data.productDetail.hsn;
                    this.gst = resp.data.productDetail.tax;
                    this.video_desc = resp.data.productDetail.desc;
                    this.keywords = resp.data.productDetail.keywords;
                    this.Rank = resp.data.productDetail.rank;

                });
                return;
            }

            runInAction(() => {
                this.ProductCatalogLoader = false;
                this.ProductCatalogpopup = false;
                this.errLoadProductCatalog = resp?.data?.message;
            });
        } catch (error) {
            runInAction(() => {
                if (!error.response) {
                    this.ProductCatalogLoader = false;
                    this.ProductCatalogpopup = false;
                    this.errLoadProductCatalog = error?.message;
                    toast.error(error?.message);
                }
                toast.error(error?.response?.data?.message);
                this.ProductCatalogLoader = false;
                this.ProductCatalogpopup = false;
                this.errLoadProductCatalog = error?.response?.data?.message;
            });
        }
    };






    // get ProductOptions list
    getProductOptionsList = () => {
        runInAction(() => {
            this.ProductOptionsLoader = true;
            this.errLoadProductOptions = "";
            this.saved = false;
            this.nolist = false;
            this.ProductOptions = [];
        });
        getMethodData(`/api/v1/admin/options/get-list`, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.ProductOptionsLoader = false;
                        this.errLoadProductOptions = resp?.data?.message
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.ProductOptionsLoader = false;
                        this.errLoadProductOptions = resp?.data?.message;
                        if (resp?.data?.data.length === 0) {
                            this.nolist = true;
                        } else {
                            this.nolist = false;
                        }
                        this.ProductOptions = resp?.data?.data;
                        this.SetTotalPages(resp?.data?.totalPage)
                        this.saved = false;
                        // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.LoadProductOptions = false;
                    this.errLoadProductOptions = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.ProductOptionsLoader = false;
                    if (!error.response) {
                        this.errLoadProductOptions = error?.message;
                        toast.error(error?.message);
                    }
                    this.errLoadProductOptions = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };

    getUnitDetails = () => {
        runInAction(() => {
            this.ProductOptionsLoader = true;
            this.errLoadProductOptions = "";
            this.saved = false;
            this.nolist = false;
            this.ProductOptions = [];
        });
        getMethodData(`/api/v1/admin/unit-detail`, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.ProductOptionsLoader = false;
                        this.errLoadProductOptions = resp?.data?.message
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.ProductOptionsLoader = false;
                        this.errLoadProductOptions = resp?.data?.message;
                        if (resp?.data?.data.length === 0) {
                            this.nolist = true;
                        } else {
                            this.nolist = false;
                        }
                        this.UnitDetails = resp?.data?.data;
                        this.SetTotalPages(resp?.data?.totalPage)
                        this.saved = false;
                        // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.LoadProductOptions = false;
                    this.errLoadProductOptions = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.ProductOptionsLoader = false;
                    if (!error.response) {
                        this.errLoadProductOptions = error?.message;
                        toast.error(error?.message);
                    }
                    this.errLoadProductOptions = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };


    // get ProductOptions list
    getProductValuesList = (id) => {
        runInAction(() => {
            this.ProductOptionsLoader = true;
            this.errLoadProductOptions = "";
            this.saved = false;
            this.nolist = false;
            this.ProductValues = [];
        });
        getMethodData(`/api/v1/admin/options-values/get-list/${id}`, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.ProductOptionsLoader = false;
                        this.errLoadProductOptions = resp?.data?.message
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.ProductOptionsLoader = false;
                        this.errLoadProductOptions = resp?.data?.message;
                        if (resp?.data?.data.length === 0) {
                            this.nolist = true;
                        } else {
                            this.nolist = false;
                        }
                        this.setproductValueid(resp?.data?.data)
                        this.ProductValues = resp?.data?.data;
                        this.SetTotalPages(resp?.data?.totalPage)
                        this.saved = false;
                        // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.LoadProductOptions = false;
                    this.errLoadProductOptions = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.ProductOptionsLoader = false;
                    if (!error.response) {
                        this.errLoadProductOptions = error?.message;
                        toast.error(error?.message);
                    }
                    this.errLoadProductOptions = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };
    getProductValuesDropdown = (id) => {
        runInAction(() => {
            this.ProductOptionsLoader = true;
            this.errLoadProductOptions = "";
            this.saved = false;
            this.nolist = false;
            this.ProductValues = [];
        });
        getMethodData(`/api/v1/admin/options-values/dropdown/${id}`, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.ProductOptionsLoader = false;
                        this.errLoadProductOptions = resp?.data?.message
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.ProductOptionsLoader = false;
                        this.errLoadProductOptions = resp?.data?.message;
                        if (resp?.data?.data.length === 0) {
                            this.nolist = true;
                        } else {
                            this.nolist = false;
                        }
                        this.setproductValueid(resp?.data?.data)
                        this.ProductValues = resp?.data?.data;
                        this.SetTotalPages(resp?.data?.totalPage)
                        this.saved = false;
                        // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.LoadProductOptions = false;
                    this.errLoadProductOptions = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.ProductOptionsLoader = false;
                    if (!error.response) {
                        this.errLoadProductOptions = error?.message;
                        toast.error(error?.message);
                    }
                    this.errLoadProductOptions = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };

    //update method
    updateProductCatalog = (id) => {
        runInAction(() => {
            this.addloadProductCatalog = true;
            this.errLoadProductCatalog = "";
        });
        const formData = new FormData();
        if (this.ProductCatalogFormDetails.logo_image) {
            const Logobase64String = this.ProductCatalogFormDetails.logo_image?.split(',')[1];
            const logoFile = this.base64toFile(Logobase64String, 'logo.png', 'image/png');
            formData.append('logo_image', logoFile);
        }


        if (this.ProductCatalogFormDetails.banner_image) {
            const Bannerbase64String = this.ProductCatalogFormDetails.banner_image?.split(',')[1];
            const BannerFile = this.base64toFile(Bannerbase64String, 'logo.png', 'image/png');
            formData.append('banner_image', BannerFile);
        }


        if (this.crop.dimensions?.height) {
            formData.append('logo_height', this.crop.dimensions?.height);
        }


        if (this.crop.dimensions?.width) {
            formData.append('logo_width', this.crop.dimensions?.width);
        }

        if (this.BannerDimiensions?.dimensions?.height) {
            formData.append('banner_height', this.BannerDimiensions?.dimensions?.height);
        }


        if (this.BannerDimiensions?.dimensions?.width) {
            formData.append('banner_width', this.BannerDimiensions?.dimensions?.width);
        }

        formData.append('ProductCatalogId', id);
        formData.append('name', this.ProductCatalogFormDetails.name);
        formData.append('seo_url', this.ProductCatalogFormDetails.seo_url);
        formData.append('status', this.ativate ? 1 : 0);
        formData.append('is_featured', this.product_featured ? 1 : 0);
        // formData.append('isBannerUpdated', false  );
        // formData.append('isLogoUpdated', true  );


        putMethod(`/api/v1/admin/ProductCatalog/edit`,
            formData,
            this.Token
        )
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.addloadcity = false;
                        this.erraddcity = resp?.data?.message;
                        toast.error(resp.data.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.addloadcity = false;
                        this.erraddcity = resp?.data?.message;
                        toast.success(resp.data.message);
                        // this.getCity();
                        this.getProductCatalogList();
                        this.saved = true;
                    })
                    return
                }
                runInAction(() => {
                    this.addloadcity = false;
                    this.erraddcity = resp?.data?.message;
                    toast.error(resp.data.message);
                });
            })
            .catch((error) => {
                runInAction(() => {
                    if (!error.response) {
                        this.addloadcity = false;
                        this.erraddcity = error?.message;
                        toast.error(error?.message);
                    }
                    toast.error(error?.response?.data?.message);
                    this.addloadcity = false;
                    this.erraddcity = error?.response?.data?.message;
                })
                return;
            })
    };

    getDesignList = (id) => {
        runInAction(() => {
            this.designIdloder = true;
            this.errLoadProductOptions = "";
            this.saved = false;
            this.nolist = false;
            this.designId = [];
        });
        getMethodData(`/api/v1/admin/products-design/get-list/${id}`, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.designIdloder = false;
                        this.errLoadProductOptions = resp?.data?.message
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.designIdloder = false;
                        this.errLoadProductOptions = resp?.data?.message;

                        this.designId = resp?.data?.data;
                        // this.setdesignIdTotalPages(resp?.data?.totalPage)
                        this.SetTotalDeginPages(resp?.data?.totalPage)
                        this.saved = false;
                        // this.status = true;
                    });
                    return;
                }
                runInAction(() => {
                    this.LoadProductOptions = false;
                    this.errLoadProductOptions = resp?.data?.message;
                });
            })
            .catch((error) => {
                runInAction(() => {
                    this.designIdloder = false;
                    if (!error.response) {
                        this.errLoadProductOptions = error?.message;
                        toast.error(error?.message);
                    }
                    this.errLoadProductOptions = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };

    addDesignId = (type) => {
        runInAction(() => {
            this.addloadaddDesignId = true;
            this.errLoadaddDesignId = "";
        });
        const formData = new FormData();

        formData.append('product_id', this.ProductCatalogid);
        formData.append(`design_id`, this.design_id);
        formData.append("is_multiple", type == "multi" ? true : false);


        postMethodData("/api/v1/admin/products-design/add", formData, this.Token).then((resp) => {
            if (resp?.data?.status === false) {
                runInAction(() => {
                    this.addloadaddDesignId = false;
                    this.errLoadaddDesignId = resp?.data?.message;
                    toast.error(resp.data.message);
                });
                return;
            }
            if (resp?.data?.status === true) {
                runInAction(() => {
                    this.addloadaddDesignId = false;
                    this.errLoadaddDesignId = resp?.data?.message;
                    // toast.success(resp.data.message);
                    this.getDesignList(this.ProductCatalogid);
                    this.setdesign_id("")

                    this.saved = true;
                })
                return
            }
            runInAction(() => {
                this.addloadaddDesignId = false;
                this.errLoadaddDesignId = resp?.data?.message;
            });

        })
            .catch((error) => {
                runInAction(() => {
                    this.addloadaddDesignId = false;
                    if (!error.response) {
                        this.errLoadaddDesignId = error?.message;
                        toast.error(error?.message);
                    }
                    this.errLoadaddDesignId = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            });
    };

    designIdstatusChange = (id, status) => {
        runInAction(() => {
            this.designIdloder = true;
            this.ProductCatalogpopup = true;
            this.setProductIdpopup(true);
            this.designId = [];
            this.errLoadProductCatalog = "";
        });
        postMethodData(`/api/v1/admin/products-design/update-status`, {

            "record_id": id,
            "status": status

        }, this.Token)
            .then((resp) => {
                if (resp?.data?.status === false) {
                    runInAction(() => {
                        this.designIdloder = false;
                        this.setProductIdpopup(true);

                        this.ProductCatalogpopup = false;
                        this.errLoadProductCatalog = resp?.data?.message;
                        toast.error(resp?.data?.message);
                    });
                    return;
                }
                if (resp?.data?.status === true) {
                    runInAction(() => {
                        this.designIdloder = false;
                        this.ProductCatalogpopup = false;
                        this.setProductIdpopup(true);
                        this.errLoadProductCatalog = resp?.data?.message;
                        this.getDesignList(this.ProductCatalogid);
                        toast.success(resp?.data?.message);
                    })
                    return;
                }
                runInAction(() => {
                    this.designIdloder = false;
                    this.ProductCatalogpopup = false;

                    this.errLoadProductCatalog = resp?.data?.message;
                })
            })
            .catch((error) => {
                runInAction(() => {
                    if (!error.response) {
                        this.designIdloder = false;
                        this.ProductCatalogpopup = false;
                        this.setProductIdpopup(true);

                        this.errLoadProductCatalog = error?.message;
                        toast.error(error?.message);
                    }
                    toast.error(error?.response?.data?.message);
                    this.designIdloder = false;
                    this.ProductCatalogpopup = false;

                    this.errLoadProductCatalog = error?.response?.data?.message;
                })
                return;
            })
    };
    //Delete method
    designIddeletefunction = (id) => {
        runInAction(() => {
            this.designIdloder = (true);
            this.deletepopup = true;
            this.errLoadProductCatalog = "";
            this.designId = [];

        });
        postMethodData(`/api/v1/admin/products-design/delete`, {

            "record_id": id,

        }, this.Token).then((resp) => {
            if (resp?.data?.status === false) {
                runInAction(() => {
                    this.LoadProductCatalog = false;
                    this.designIdloder = true;
                    this.deletepopup = true;
                    this.errLoadProductCatalog = resp?.data?.message;
                    toast.error(resp.data.message);
                });
                return;
            }
            if (resp?.data?.status === true) {
                runInAction(() => {
                    this.LoadProductCatalog = false;
                    this.designIdloder = false;
                    this.deletepopup = false;
                    this.errLoadProductCatalog = resp?.data?.message;
                    this.getDesignList(this.ProductCatalogid);
                    // toast.success(resp.data.message);
                })
                return;
            }
            runInAction(() => {
                this.LoadProductCatalog = false;
                this.errLoadProductCatalog = resp?.data?.message;
            });

        })
            .catch((error) => {
                runInAction(() => {
                    this.addloadcity = false;
                    if (!error.response) {
                        this.LoadProductCatalog = false;
                        this.designIdloder = false;
                        this.deletepopup = false;
                        this.errLoadProductCatalog = error?.message;
                        toast.error(error?.message);
                    }
                    this.LoadProductCatalog = false;
                    this.designIdloder = false;
                    this.errLoadProductCatalog = error?.response?.data?.message;
                    toast.error(error?.response?.data?.message);
                })
                return;
            })
    }



}




export const ProductCatalogStoreContext = createContext(new ProductCatalogStore());
export const useProductCatalogStore = () => useContext(ProductCatalogStoreContext);

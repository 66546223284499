import React, { useState } from "react";
import { RxEyeClosed, RxEyeOpen, RxPencil1 } from "react-icons/rx";
import { Link, useParams } from "react-router-dom";
import { MdAdd, MdBlock, MdEdit, MdLocationCity, MdLockPerson, MdMinimize, MdOutlineDelete, MdOutlineGroup, MdOutlineLockPerson, MdPassword, MdPerson, MdPerson3, MdRemoveRedEye } from "react-icons/md";
import { observer } from "mobx-react";
import ReactPaginate from 'react-paginate';
// import { BrandStore, useBrandStore, BrandStoreContext } from "./store/BrandStore";
// import { AdminUseStore, useAdminUsedStore, AdminUserStoreContext } from "./store/AdminUsersStore";
import { CategoriesUseStore, useCategoriesStore, CategoriesStoreContext } from "./Store/CategoriesManagement";
import { useAuthStore } from "../Auth/store/Auth";
import Filter from "./CategoriesFilter/Filters";
import { useEffect } from "react";
// You need to import the CSS only once
import "react-awesome-lightbox/build/style.css";
import { toast } from "react-toastify";

const Categories = () => {
    const { id } = useParams();

    const store = new CategoriesUseStore({
        loadeditcitybol: false,
        isloading: true,
        AdminPermissions: true,
        id: id
    });
    return (
        <CategoriesStoreContext.Provider value={store}>
            <Screen />
        </CategoriesStoreContext.Provider>
    )
}
export default Categories;

const SubLevelComp = observer(({ item, renderNestedLevels }) => {
    const [selected, setselected] = React.useState(false);
    const BASE_URL = process.env.REACT_APP_BASE_URL;

    const [status, setStatus] = useState(item?.is_active === 1);
    const {
        root_categories,
        Catloader,
        deletepopup,
        delateLoader,
        getToken,
        setdelateLoader,
        deletefunction,
        getid,
        setdeletepopup,
        statusChange,
        category_id
    } = useCategoriesStore();
    const { adminAuthApproved } = useAuthStore();

    useEffect(() => {
        if (adminAuthApproved) {
            getToken(localStorage.getItem("Admintoken"));
        }

    }, [adminAuthApproved]);
    const toggle = (e) => {
        // Check if the click is on the plus button
        const isPlusButton = e.target.classList.contains('plus-button');

        if (isPlusButton) {
            setselected(!selected);
        }
    };
    const handleToggle = () => {
        const newStatus = !status;
        setStatus(newStatus);

        // Assuming you have a function to update the is_active property in your item
        statusChange(item.id, newStatus ? 1 : 0);

    };
    const hasChildren = (item) => {
        return (
            Array.isArray(item.child_category) && item.child_category.length > 0
        );
    };
    console.log("Is true or not", Catloader);
    return (
        <div>

            <p
                onClick={(e) => hasChildren(item) && toggle(e)}
                className={`pt-3 pb-3 bg-[white] flex justify-between   mt-1 border-[1px] ${hasChildren(item) ? 'cursor-pointer' : ''
                    }`}
            >
                <div>
                    {hasChildren(item) ? (
                        <span
                            className="bg-[#efefef] p-[14px] plus-button"
                        >
                            {selected ? '-' : '+'}
                        </span>
                    )
                        :
                        <span
                            className="bg-[#efefef] py-[14px] px-[17px] plus-button"
                        >

                        </span>
                    }
                    <span className="ml-3 border-dashed border-[black] hover:text-color_theme border-b-[1px]">
                        {item.name}
                    </span>

                </div>
                <div className="px-3 flex space-x-2 items-center ">
                    {item.is_product == 1 &&
                        <img crossOrigin="anonymous" className="w-11 h-11 rounded-full" src={item.image?.includes("cloudinary")
                            ? item.image
                            : BASE_URL + `/uploads/productCategory/${item.image}`} />
                    }

                    <label class="relative  cursor-pointer">

                        {/* <span class=" text-sm  font-medium text-gray-900 dark:text-gray-300"> Allow user to receive system generated alerts</span> */}
                        <div className="relative">
                            <input
                                value={status ? 0 : 1}
                                checked={status}
                                onChange={handleToggle}
                                type="checkbox"
                                className="sr-only peer"
                            />
                            <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>

                        </div>
                    </label>
                    <button
                        onClick={() => { setdeletepopup(true); getid(item.id) }}

                    >
                        <label
                            htmlFor="deletecategories"
                            className=" text-[#d02c2c] cursor-pointer"
                            title="Delete"
                        >
                            <MdOutlineDelete />
                        </label>
                    </button>




                    <button title="Edit">
                        <Link to={`/categories/${item?.id}`}>
                            <RxPencil1 />
                        </Link>
                    </button>
                </div>


            </p>

            {selected && hasChildren(item) && (
                <div className="flex">
                    <div className="bg-[#efefef] p-[16px]"></div>
                    <div className="mt-[15px] w-full ml-[3px]">
                        {renderNestedLevels(item.child_category)}
                    </div>
                </div>
            )}
            {deletepopup &&
                <>
                    <input type="checkbox" id="deletecategories" className="modal-toggle" />
                    <div className="modal">
                        <div className="modal-box relative">
                            <label

                                className="btn btn-sm btn-circle absolute right-2 top-2"
                                onClick={() => { setdelateLoader(false); setdeletepopup(false) }}
                            >
                                ✕
                            </label>
                            <h3 className="text-lg font-bold text-center">
                                Are you sure you want to Delete this Category
                            </h3>
                            <div className="modal-action justify-center">
                                {/* <label
                                        htmlFor="deletecategories"
                                        className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                                        onClick={() => deletefunction()}
                                    >
                                        Yes
                                    </label> */}
                                {!delateLoader ?
                                    <label

                                        className="btn btn-md bg-color_theme text-[#000] text-sm hover:text-color_theme hover:bg-[#000] ml-4"
                                        onClick={() => deletefunction(category_id)}
                                    >
                                        Yes
                                    </label> :
                                    <button
                                        // type="submit"
                                        disabled
                                        className="flex items-center bg-color_theme px-3 mx-5 rounded-md"
                                    // onClick={() => {
                                    //   PostMatchscore();
                                    // }}
                                    >
                                        Yes..
                                        <svg
                                            className="animate-spin mr-1 h-5 w-5 text-white"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                        >
                                            <circle
                                                className="opacity-25"
                                                cx="12"
                                                cy="12"
                                                r="10"
                                                stroke="currentColor"
                                                strokeWidth="4"
                                            ></circle>
                                            <path
                                                className="opacity-75"
                                                fill="currentColor"
                                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                            ></path>
                                        </svg>
                                    </button>}
                                <label
                                    onClick={() => setdeletepopup(false)}
                                    className="btn btn-md bg-[#000] text-color_theme text-sm hover:bg-[#000]">
                                    No
                                </label>
                            </div>
                        </div>
                    </div>
                </>
            }
        </div>
    );
});

const Accordion = observer(() => {
    const {
        root_categories,
        deletepopup,
        delateLoader,
        setdelateLoader,
        deletefunction,
        getid,
        setdeletepopup,
        statusChange,
        getToken
    } = useCategoriesStore();
    const renderNestedLevels = (data) => {
        return data.map((item, itemIndex) => (
            <div key={itemIndex}>
                <SubLevelComp item={item} renderNestedLevels={renderNestedLevels} />

            </div>
        ));
    };

    return renderNestedLevels(root_categories);
});

const Screen = observer(() => {

    const {
        root_categories,
        Catloader,
        deletepopup,
        nolist,
        delateLoader,
        setdelateLoader,
        deletefunction,
        getid,
        setdeletepopup,
        statusChange,
        getToken
    } = useCategoriesStore();
    const { adminAuthApproved } = useAuthStore();

    console.log("deletepopup", deletepopup);

    useEffect(() => {
        if (adminAuthApproved) {
            getToken(localStorage.getItem("Admintoken"));
        }

    }, [adminAuthApproved]);



    const handleClick = () => {
        const element = document.getElementById("tablescroll");
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    }






    return (
        <>
            <div className="flex justify-between mt-[51px] ">
                <div className=" mb-4">
                    <p className=" opacity-80 text-sm">Home / Product Categories   </p>

                </div>
                <div>
                    <Link
                        to="./addcategories"
                        className="btn btn-sm bg-[#000] text-color_theme text-sm hover:bg-[#000]"
                    >
                        <MdAdd />
                        Add Categories
                    </Link>
                </div>

            </div>
            {/* <Filter /> */}
            {Catloader ?
                <>
                    <svg
                        className="animate-spin  mr-0 ml-[45%] mt-[15%] h-[5%] w-[5%] text-color_theme"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                    >
                        <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                        ></circle>
                        <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                    </svg>
                </>
                :
                root_categories.length > 0 &&
                <div className="card bg-base-100 shadow-md p-6 mt-8">
                    <div className="overflow-x-auto  w-full">

                        {/* <NestedAccordion /> */}
                        <Accordion />
                    </div>
                </div>


            }
            {nolist &&


                <div className="card bg-base-100 shadow-md p-6 mt-8">
                    <div className="overflow-x-auto flex justify-center  w-full">

                        {/* <NestedAccordion /> */}
                        {root_categories.length == 0 &&
                            <tr>
                                <td colSpan={7} className="my-10 text-center">
                                    <MdOutlineGroup className=" text-[#dedede] text-8xl mx-auto" />
                                    <p className=" font-semibold text-lg">No ProductCatalog Found</p>
                                    <Link
                                        to="./addcategories"
                                        className="btn btn-sm bg-[#000] text-color_theme text-sm hover:bg-[#000]"
                                    >
                                        <MdAdd />
                                        Add Categories
                                    </Link>
                                </td>
                            </tr>}
                    </div>
                </div>

            }





        </>
    );
});

